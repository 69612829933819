import React, { useEffect, useRef, useState } from "react";
import Product from "./product";
import style from "./CopyStore.module.css"
import { Link, NavLink ,useLocation} from "react-router-dom";
import { animateScroll } from "react-scroll";
import Hamburger from "hamburger-react";

const CopyStore = () => {
  const [isOpen, setOpen] = useState(false);
  // Assuming you have a list of products

//   const discountProducts = {
//     title:"Shop the Sale",
//     subTitle:"40% off EDventures",
//     box :[
//       { id: 1, image:"/image/sss4.jpg" , name: "Flamingo EDventure", price: "$23.97" ,link:"https://checkout.myzoobox.com/checkout/add/119421"},
//       { id: 2,  image:"/image/anteater.jpg" ,name: "Anteater EDventure", price: "$23.97" ,link:"https://checkout.myzoobox.com/checkout/add/119418"},
//       { id: 3,  image:"/image/or.jpg" ,name: "Orangutan EDventure", price: "$23.97" ,link:"https://checkout.myzoobox.com/checkout/buy/294289"},
//       { id: 4,  image:"/image/sss2.jpg" ,name: "Penguin EDventure",stock:"Low Stock Less Than 10", price: "$23.97" ,link:"https://checkout.myzoobox.com/checkout/add/247022"},
//       { id: 5,  image:"/image/sss19.jpg" ,name: "Monkey EDventure", price: "$23.97" ,link:"https://checkout.myzoobox.com/checkout/add/119414?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      
   
//     ]
// }
// const discountProductsTwo = {
//   title:"Shop the Sale",
//   subTitle:"25% off EDventures",
//   box :[
//     { id: 1, image:"/image/sss20.jpg" ,name: "Bee EDventure", price: "$29.96 " ,link:"https://checkout.myzoobox.com/checkout/add/254994"},

//     { id: 2,  image:"/image/sss21.jpg" ,name: "Butterfly EDventure", price: "$29.96" ,link:"https://checkout.myzoobox.com/checkout/add/247032?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
  
//     { id: 3,  image:"/image/sss26.jpg" ,name: "Stingray EDventure     ", price: "$29.96" ,link:"https://checkout.myzoobox.com/checkout/add/247024?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     { id: 4, image:"/image/asd.jpg" ,name: "Hammerhead Shark EDventure", price: "$29.96 " ,link:"https://checkout.myzoobox.com/checkout/add/257095"},
//     { id: 5,  image:"/image/sss1.jpg" ,name: "Sea Lion EDventure", price: "$29.96" ,link:"https://checkout.myzoobox.com/checkout/add/247026"},
//     { id: 6,  image:"/image/sss3.jpg" ,name: "Kangaroo EDventure",stock:"Low Stock", price: "$29.96" ,link:"https://checkout.myzoobox.com/checkout/add/121029"},
//     { id: 7,  image:"/image/sss9.jpg" ,name: "Elephant EDventure", price: "$29.96" ,link:"https://checkout.myzoobox.com/checkout/add/119411?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     { id: 8,  image:"/image/sss14.jpg" ,name: "Alligator EDventure", price: "$29.96" ,link:"https://checkout.myzoobox.com/checkout/add/119419?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     // { id: 9,  image:"/image/sss23.jpg" ,name: "Sea Star EDventure      ", price: "$29.96" ,link:"https://checkout.myzoobox.com/checkout/add/255002?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     { id: 10, image:"/image/sss24.jpg" ,name: "Beluga EDventure", price: "$29.96 " ,link:"https://checkout.myzoobox.com/checkout/add/247028?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//   ]
// }
// const discountProductsThree = {
//   title:"Shop the Sale",
//   subTitle:"10% off EDventures",
//   box :[
//     // { id: 1, image:"/image/sss27.jpg" ,name: "Walrus EDventure", price: "$35.96" ,stock:"Low Stock Less Then 5",link:"https://checkout.myzoobox.com/checkout/add/119415?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     // { id: 2,  image:"/image/sss5.jpg" ,name: "Deer EDventure", price: "$35.96" ,stock:"Low Stock Less Then 5",link:"https://checkout.myzoobox.com/checkout/add/119420"},
//     { id: 3,  image:"/image/sku.jpg" ,name: "Skunk EDventure", price: "$35.96" ,link:"https://checkout.myzoobox.com/checkout/buy/53539 "},
//     { id: 4,  image:"/image/h.jpg" ,name: "Seahorse EDventure", price: "$35.96" ,link:"https://checkout.myzoobox.com/checkout/buy/294302 "},
//     { id: 5, image:"/image/sss8.jpg" ,name: "Fennec Fox EDventure", price: "$35.96" ,link:"https://checkout.myzoobox.com/checkout/add/255000?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     { id: 6,  image:"/image/sss12.jpg" ,name: "Bear EDventure", price: "$35.96" ,link:"https://checkout.myzoobox.com/checkout/add/254996?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     { id: 7, image:"/image/sss13.jpg" ,name: "Jaguar EDventure", price: "$35.96 " ,link:"https://checkout.myzoobox.com/checkout/add/247030?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     { id: 8, image:"/image/sss17.jpg",name: "Chameleon EDventure", price: "$35.96" ,link:"https://checkout.myzoobox.com/checkout/add/232553?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//     { id: 9,  image:"/image/sss16.jpg" ,name: "Lemur EDventure", price: "$35.96" ,link:"https://checkout.myzoobox.com/checkout/add/247020?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
//   ]
// }


  const products = {
      title:"Fan Favorites",
      box :[
        { id: 1, image:"/image/asd.jpg" ,name: "Hammerhead Shark EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/257095"},
        { id: 2,  image:"/image/sss1.jpg" ,name: "Sea Lion EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/247026"},
        // { id: 3,  image:"/image/sss2.jpg" ,name: "Penguin EDventure",stock:"Low Stock Less Than 10", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/247022"},
        { id: 5, image:"/image/sss4.jpg" , name: "Flamingo EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/119421"},
        // { id: 6,  image:"/image/sss5.jpg" ,name: "Deer EDventure",stock:"Low Stock Less Then 5", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/119420"},
        { id: 7,  image:"/image/sku.jpg" ,name: "Skunk EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/buy/53539 "},
        { id: 8,  image:"/image/h.jpg" ,name: "Seahorse EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/buy/294302 "},

     
      ]
  }

  const productsTwo = {
    title:"African Animals",
    box :[
      // { id: 1, image:"/image/sss8.jpg" ,name: "Fennec Fox EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/255000?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      { id: 2,  image:"/image/sss9.jpg" ,name: "Elephant EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/119411?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},

      // Add more products as needed
    ]
}


const productsThree = {
    title:"Predators",
    box :[
      // { id: 1, image:"/image/sss10.jpg" ,name: "Hammerhead Shark EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/257095?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      { id: 2,  image:"/image/sss12.jpg" ,name: "Bear EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/254996?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      // { id: 1, image:"/image/sss13.jpg" ,name: "Jaguar EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/247030?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      { id: 2,  image:"/image/sss14.jpg" ,name: "Alligator EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/119419?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      // { id: 4,  image:"/image/sss3.jpg" ,name: "Kangaroo EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/121029"},

    ]
}


const productsFour = {
    title:"Rainforest Animals",
    box :[
      // { id: 1, image:"/image/sss15.jpg" ,name: "Jaguar EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/247030?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      { id: 2,  image:"/image/sss16.jpg" ,name: "Lemur EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/247020?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      // { id: 3, image:"/image/sss17.jpg",name: "Chameleon EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/232553?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      // { id: 4,  image:"/image/anteater.jpg" ,name: "Anteater EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/119418"},
      { id: 5,  image:"/image/sss19.jpg" ,name: "Monkey EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/119414?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      // { id: 7,  image:"/image/or.jpg" ,name: "Orangutan EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/buy/294289"},

      // Add more products as needed
    ]
}
const productsFive = {
    title:"Animals That Fly    ",
    box :[
      { id: 1, image:"/image/sss20.jpg" ,name: "Bee EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/254994"},

      { id: 2,  image:"/image/sss21.jpg" ,name: "Butterfly EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/247032?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
     
      { id: 3, image:"/image/sss4.jpg" , name: "Flamingo EDventure", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/119421"},

      // Add more products as needed
    ]
}

const productsSix = {
    title:"Ocean Animals",
    box :[
      { id: 1, image:"/image/asd.jpg" ,name: "Hammerhead Shark EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/257095?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      { id: 2,  image:"/image/sss23.jpg" ,name: "Sea Star EDventure      ", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/255002?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      // { id: 3, image:"/image/sss24.jpg" ,name: "Beluga EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/247028?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      { id: 4, image:"/image/sss25.jpg" ,name: "Sea Lion EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/247026?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      { id: 4, image:"/image/SeaHorse.webp" ,name: "Seahorse EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/buy/294302 "},

      // { id: 5,  image:"/image/sss26.jpg" ,name: "Stingray EDventure     ", price: "$39.95" ,link:"https://checkout.myzoobox.com/checkout/add/247024?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},
      // { id: 6, image:"/image/sss27.jpg" ,name: "Walrus EDventure", stock:"Low Stock Less Then 5",price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/add/119415?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue"},

      // Add more products as needed
    ]
}
const productsSeven = {
  title:"North American Animals",
  box :[
    { id: 1, image:'/image/Skunk.webp' ,name: "Skunk EDventure", price: "$39.95 " ,link:"https://checkout.myzoobox.com/checkout/buy/53539 "},
   
    // Add more products as needed
  ]
}


const productsEight= {
  title:"Digital Unit Studies",
  box :[
    { id: 1, image:'/image/d1.png' ,name: "Arctic Fox Unit Study", price: "$5.00" ,link:"https://checkout.myzoobox.com/checkout/buy/292221"},
    { id: 2, image:'/image/d2.png' ,name: "Elephant Unit Study", price: "$7.00" ,link:"https://checkout.myzoobox.com/checkout/buy/292188"},
    // Add more products as needed
    { id: 3, image:'/image/d3.png' ,name: "Skunk Unit Study", price: "$5.00" ,link:"https://checkout.myzoobox.com/checkout/buy/292217"},
    // Add more products as needed
    { id: 4, image:'/image/d4.png' ,name: "Moose Unit Study", price: "$5.00" ,link:"https://checkout.myzoobox.com/checkout/buy/291857"},
    { id: 5, image:'/image/d5.png' ,name: "Seahorse Unit Study", price: "$5.00" ,link:"https://checkout.myzoobox.com/checkout/buy/291011"},
    { id: 6, image:'/image/d6.png' ,name: "Hummingbird Unit Study", price: "$5.00" ,link:"https://checkout.myzoobox.com/checkout/buy/292215"},
    { id: 7, image:'/image/d7.png' ,name: "Pangolin Unit Study", price: "$5.00" ,link:"https://checkout.myzoobox.com/checkout/buy/283068"},
    { id: 8, image:'/image/d8.png' ,name: "Alligator Unit Study", price: "$7.00" ,link:"https://checkout.myzoobox.com/checkout/buy/283070"},
  ]
}


const sectionOffer = useRef(null);
const sectionOfferTwo = useRef(null);
const sectionOfferThree = useRef(null);

const section1Ref = useRef(null);
const section2Ref = useRef(null);
const section3Ref = useRef(null);
const section4Ref = useRef(null);
const section5Ref = useRef(null);
const section6Ref = useRef(null);

  const box = [
    {
      image:"/image/el.png",
      name:"African Animals",
      add:"afr",
      scroll:section2Ref
    },
    {
      image:"/image/e2.png",
      name:"Predators ",
      add:"pred",
      scroll:section3Ref
    },
    {
      image:"/image/e3.png",
      name:"Rainforest Animals",
      add:"rain",
      scroll:section4Ref
    },
    {
      image:"/image/e4.png",
      name:"Animals that Fly",
      add:"fly",
      scroll:section5Ref
    },
    {
      image:"/image/shark.png",
      name:"Ocean Animals",
      add:"ocean",
      scroll:section6Ref
    },
  ]
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.subblyConfig = {
          apiKey: '52d2a9b4-a884-42cb-83a9-cfb67f347926',
          settings: {
              interceptProductLinks: true,
              cartCounterEl: '.subbly-cart-product-count',
              cartToggleEl: '.subbly-cart',
              cartButton: true,
          },
      };
    `;
    document.body.appendChild(script);

    const subblyScript = document.createElement('script');
    subblyScript.type = 'module';
    subblyScript.src = 'https://assets.subbly.co/cart/cart-widget.js';
    document.body.appendChild(subblyScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(subblyScript);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sectionToScroll = queryParams.get("section");

    if (sectionToScroll === "40") {
      scrollToSection(sectionOffer);
    } else if (sectionToScroll === "25") {
      scrollToSection(sectionOfferTwo);
    } else if (sectionToScroll === "10") {
      scrollToSection(sectionOfferThree);
    }
  }, [location.search]);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    };
  return (
    <div className={style.store}>
     
        <div className={style.storeNavBck}>
        <div className={`thirdContainer ${style.storeNav}`}>
                  <div>
                  <p onClick={() => scrollToSection(section1Ref)}>Fan Favorites</p>
            <p onClick={() => scrollToSection(section2Ref)}>African Animals</p>
            <p onClick={() => scrollToSection(section3Ref)}>Predators</p>
            <p onClick={() => scrollToSection(section4Ref)}>Rainforest Animals</p>
            <p onClick={() => scrollToSection(section5Ref)}>Animals That Fly</p>
            <p onClick={() => scrollToSection(section6Ref)}>Ocean Animals</p>
       
                  </div>
        </div>
        </div>
        <div className={`${style.mobileNav} container`}>
        <div className={style.mobileDisplay}>
        
          <div className={style.mobileList}>
            <div className={style.mobileHeaderData}>
              <div className={`hamMenu ${style.hamburgerMenu}`}>
            
                <p onClick={() => setOpen(!isOpen)}>Browse Categories</p>
              </div>
            </div>
          </div>
        </div>
      
      </div>
  
    <div
          className={`${style.mobileMenuContainer} ${isOpen && style.opened}`}
        >
          <img src="/image/add.svg" className={style.closeMob} onClick={() => setOpen(!isOpen)}/>
          <h2>Shop EDventures</h2>
           <div className={style.mobSection}>
            {box.map((item => {
              return(
             <div className={style.sectionBox}  onClick={() => {
              setOpen(false);
              scrollToSection(item.scroll)
            }} >
                <img src={item.image}/>
                <div className={`${style.sectionCont} ${style[item.add]}`}>
                     <h3>{item.name}</h3>
                     <div>
                     <p>View EDventures</p>
                     <img src="/image/icon/arrow.svg"/>
                      </div>
                   
                </div>
              </div>
              )
            }))}
              
           </div>
   
        </div>
   
       <div className={style.storeBanner}>
       <img src="/image/storeBanner.svg"/>
       <h2>The Zoo Store</h2>
       </div>
{/*      
        <Product refOffer={sectionOffer} refOfferTwo={sectionOfferTwo} refOfferThree={sectionOfferThree} refOne={section1Ref} refTwo={section2Ref} refThree={section3Ref} refFour={section4Ref} refFive={section5Ref} refSix={section6Ref}    discountProduct={discountProducts} discountProductTwo={discountProductsTwo}  discountProductThree={discountProductsThree} 
         
        /> */}
     
     <Product refOne={section1Ref} refTwo={section2Ref} refThree={section3Ref} refFour={section4Ref} refFive={section5Ref} refSix={section6Ref}   product={products}  productTwo={productsTwo} productFour={productsFour} productThree={productsThree} productFive={productsFive} productSix={productsSix} productSeven={productsSeven} productEight={productsEight}
         
         />
    </div>
  );
};

export default CopyStore;
